import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {RouterProvider} from "react-router-dom";
import React, {useState} from "react"; //https://www.kindacode.com/article/how-to-use-bootstrap-5-and-bootstrap-icons-in-react/


const App = ({children}) => {
    const [user, setUser] = useState(() => {
        return  localStorage.getItem('user');
    });

    console.log("App.js")
    return (
        <>
            {children}
        </>
    );
}

export default App;
