import './App.css';
import {useLocation, redirect, useNavigate, createSearchParams} from "react-router-dom";
import {useState, useEffect, useMemo } from 'react';
import LayoutMenu from "./LayoutMenu";
import {convertiDataYYYYMMDD} from "./utils";

// https://stackoverflow.com/questions/64782949/how-to-pass-params-into-link-using-react-router-v6
function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function BolleViaggiantiPage() {
    let [data, setData] = useState([{}]);
    const navigate = useNavigate();

    function reloadState() {
        let userLogged = JSON.parse(localStorage.getItem('user'))

        fetch(process.env.REACT_APP_API_URL+"/bolle-viaggianti",
            {
                headers: {
                    // 'Authorization': 'Bearer ' + process.env.REACT_APP_APIKEY,
                    'Authorization': 'Bearer ' + userLogged.jwt,
                }
            })
            //.then((res) => res.json())
            .then((res) => {
                if (!res.ok) {
                    // Se il codice di stato non è OK (200), solleva un errore
                    throw new Error(`Errore API: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                setData(data);
            })
            .catch((err) => {
                console.log(err.message);
                if (err.message.includes("40")) {
                    console.log("Errore 400")
                    alert("Errore di autenticazione, ritenta il login ed in caso di problemi contatta ict@noberasco.it")
                    navigate('/logout')

                } else if (err.message.includes("50")) {
                    console.log("Errore 500")
                    alert("Errore del server. Contatta ICT")
                    //navigate('/home')
                }
            });
    }

    useEffect(() => {
        console.log(`/bolle-viaggianti`);
        reloadState()
    }, []);

    return (
        <LayoutMenu>
                {/*<div className="breadcrumb-1"><a href="/">HOME</a> / BOLLE</div>*/}
                <h1>BOLLE</h1>
                <div className="col col-12">
                    <div className="mt-3">
                        {data === null ? (
                            <div>
                                {/* Il contenuto da mostrare quando la lunghezza della variabile è 0 */}
                                <p>Nessuna bolla da mostrare.</p>
                            </div>
                        ) : (
                            data?.map((d, i) => {

                                return (
                                    <div  onClick={() => navigate({
                                        pathname : "/bolla",
                                        search: createSearchParams({ bollaNumero: d.numero, bollaData: d.data }).toString()
                                    })}
                                          style={{background: "#e1d8be", cursor : "pointer"}}
                                          className="mt-2 d-flex flex-row mt-2 flx-items-table flx-items-table-no-details">
                                        <div className="p-2 w-300"><strong>Numero <br></br>{d.numero}</strong></div>
                                        <div className="p-2 w-180"><strong>Ordine </strong><br></br>{d.ordine}</div>
                                        <div className="p-2 w-180"><strong>Data </strong><br></br>{convertiDataYYYYMMDD(d.data)}</div>
                                        <div className="p-2 w-180"><strong>Negozio </strong><br></br>{d.clienteDescr}</div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                    {/*<div className="card">
                        <div className="card-body">
                            <table className="table table-sm table-hover table-pointer w-auto">
                                <thead>
                                <tr>
                                    <th>Bolla Num.</th>
                                    <th>Ordine</th>
                                    <th>Cliente</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.map((d, i) => {
                                    return (
                                        <tr key={i}  onClick={() => navigate({
                                            pathname : "/bolla",
                                            search: createSearchParams({ bollaNumero: d.numero, bollaData: d.data }).toString()
                                        })}>
                                            <td>{d.numero}</td>
                                            <td>{d.ordine}</td>
                                            <td>{d.clienteDescr}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>

                    </div>*/}

                </div>
        </LayoutMenu>
    );
}


export default BolleViaggiantiPage;