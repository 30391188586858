// Home.js
import React from 'react';
import LayoutMenu from './LayoutMenu';

const Home = () => {
    return (
        <LayoutMenu>
            <h1>Noberasco 1908</h1>
            <p>home page</p>
        </LayoutMenu>
    );
};

export default Home;