import './App.css';
import LayoutMenu from "./LayoutMenu";
import {useLocation, useNavigate, createSearchParams} from "react-router-dom";
import React, {useState, useEffect, useMemo} from 'react';

// https://stackoverflow.com/questions/64782949/how-to-pass-params-into-link-using-react-router-v6
function useQuery() {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function BollaPage() {
    let [data, setData] = useState([{righe: []}]);
    let query = useQuery();
    let navigate = useNavigate();

    // https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication#answer-43133108
    function downloadCSVAuth() {
        let userLogged = JSON.parse(localStorage.getItem('user'))
        let anchor =document.createElement("a");
        let file =  process.env.REACT_APP_API_URL + "/bolla?" + createSearchParams({
            numeroBolla: data.numero,
            dataBolla: data.data,
            csv: "true",
            file: "true"
        }).toString();

        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + userLogged.jwt);

        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = 'BF'+data.numero+'.csv';
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }


    function reloadState() {
        let userLogged = JSON.parse(localStorage.getItem('user'))

        fetch(process.env.REACT_APP_API_URL + "/bolla?numeroBolla=" + query.get('bollaNumero') + "&dataBolla=" + query.get('bollaData'),
            {
                headers: {
                    'Authorization': 'Bearer ' + userLogged.jwt,
                }
            })
            //.then((res) => res.json())
            .then((res) => {
                if (!res.ok) {
                    // Se il codice di stato non è OK (200), solleva un errore
                    throw new Error(`Errore API: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                console.log("Query params bollaNumero:", query.get('bollaNumero'), " bollaData:", query.get('bollaData'))
                setData(data);
            })
            .catch((err) => {
                console.log(err.message);
                if (err.message.includes("40")) {
                    alert("Errore di autenticazione, ritenta il login ed in caso di problemi contatta ict@noberasco.it")
                    navigate('/logout')
                } else if (err.message.includes("50")) {
                    alert("Errore del server. Contatta ICT")
                    //navigate('/home')
                }
            });
    }

    useEffect(() => {
        console.log(`/bolla`);
        reloadState()
    }, []);

    return (
        <LayoutMenu>
            {/*https://stackoverflow.com/questions/52039083/handle-back-button-with-react-router#answer-72334436*/}
            <div className="breadcrumb-1"><a href="#" onClick={() => navigate(-1)}><i className="fs-4 bi-arrow-left"></i> INDIETRO</a></div>
            <h2>BOLLA {data?.numero}</h2>
            <div className="col col-sm-12">
                {data?.righe === null && (
                    <div>Nessuna riga da mostrare.</div>
                )}

                {data?.righe?.length > 0 && (
                    <div className="card">
                        <div className="card-body">

                            <table className="table table-sm w-auto">
                                <thead>
                                <tr>
                                    <th>Cod.</th>
                                    <th>Art.</th>
                                    <th>Qta</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data?.righe?.map((d,v) => {
                                    return (
                                        <tr key={d.articoloCodUv.trim()+v}>
                                            <td>{d.articoloCodUv}</td>
                                            <td>{d.articoloDescrUv}</td>
                                            <td>{d.qtaUv}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            <a href={"#"} id="csv-download" onClick={downloadCSVAuth}>Download File per PASSEPARTOUT</a>
                        </div>
                    </div>
                )}
            </div>
        </LayoutMenu>
    );
}

export default BollaPage;