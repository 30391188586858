import '../../App.css';
import * as util from "../../utils";
import LayoutMenu from "../../LayoutMenu";
import {useLocation, useNavigate, createSearchParams} from "react-router-dom";
import React, {useState, useEffect, useMemo} from 'react';
import {convertiDataFromYYYYMMDD} from "../../utils";

// https://stackoverflow.com/questions/64782949/how-to-pass-params-into-link-using-react-router-v6
function useQuery() {
    const {search} = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

function AmnPage() {
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    let navigate = useNavigate();

    const handleSubmitZipOrdini = (event) => {
        event.preventDefault();
        downloadZipCsvOrdini(fromDate, toDate);
    };

    // https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication#answer-43133108
    function downloadZipCsvOrdini(fromDate, toDate) {
        let userLogged = JSON.parse(localStorage.getItem('user'))
        let anchor =document.createElement("a");
        let file =  process.env.REACT_APP_API_URL + "/ordini-as-pass/csv?" + createSearchParams({
            from: fromDate,
            to: toDate
        }).toString();

        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + userLogged.jwt);

        fetch(file, { headers })
            .then((res) => {
                if (!res.ok) {
                    return res.json().then(data => {
                        console.log()
                        throw new Error(`Errore API: ${res.status}, ${data.msg}`);
                    });
                }
                return res.blob();
            })
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = `NVDM_CsvOrdini_${fromDate}_${toDate}.zip`;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            })
            .catch(error => {
                console.error("Errore durante il fetch:", error);
                alert(error)
                // Puoi gestire l'errore qui, ad esempio mostrando un messaggio all'utente
            });
    }

    function reloadState() {
        let userLogged = JSON.parse(localStorage.getItem('user'))
    }

    useEffect(() => {
        console.log(`/amministrazione`);
        reloadState()
    }, []);

    return (
        <LayoutMenu>
            {/*https://stackoverflow.com/questions/52039083/handle-back-button-with-react-router#answer-72334436*/}
            <div className="breadcrumb-1"><a href="#" onClick={() => navigate(-1)}><i className="fs-4 bi-arrow-left"></i> INDIETRO</a></div>
            <h2>Amministrazione</h2>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Download CSV Ordini da AS</h5>
                    <span>Download csv ordini (al momento Treviso e Sanremo) da AS400 (ex Websmart)</span>
                    <form className={"mt-3"} onSubmit={handleSubmitZipOrdini} style={{ maxWidth: '500px' }}>
                        <div className="row">
                            <div className="col">
                                {/* Campo Data "From" */}
                                <input
                                    type="date"
                                    className="form-control"
                                    value={util.convertiDataFromYYYYMMDD(fromDate)}
                                    onChange={(e) => setFromDate(util.convertiDataToYYYYMMDD(e.target.value))}
                                    style={{ width: '150px' }}
                                    required
                                />
                            </div>
                            <div className="col">
                                {/* Campo Data "To" */}
                                <input
                                    type="date"
                                    className="form-control"
                                    value={util.convertiDataFromYYYYMMDD(toDate)}
                                    onChange={(e) => setToDate(util.convertiDataToYYYYMMDD(e.target.value))}
                                    style={{ width: '150px' }}
                                    required
                                />
                            </div>
                            {/* Bottone di invio */}
                            <div className="col">
                                <button type="submit" className="btn btn-primary">Download</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </LayoutMenu>
    );
}

export default AmnPage;