import './App.css';
import {GoogleLogin} from '@react-oauth/google';
import {useNavigate} from 'react-router-dom';
// import React, { useState } from 'react';
import LayoutLoggedOut from "./LayoutLoggedOut";
import {useState} from "react";

function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}



function LoginGooglePage() {
    const navigate = useNavigate();

    function setUserDBLocalStorage(jwt) {
        fetch(process.env.REACT_APP_API_URL + "/user",
            {
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                }
            })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Errore API: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                localStorage.setItem('userDB', JSON.stringify(data));
                navigate("/")
            })
            .catch((err) => {
                console.log(err.message);
                alert("Errore dati utente, ritenta il login ed in caso di problemi contatta ict@noberasco.it")
                //navigate('/logout')
            });
    }

    function setUserLocalStorage(user) {
        localStorage.setItem('isLogged', JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(user));
    }

    return (
        <LayoutLoggedOut>
            <h1>Login</h1>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    let googleUserInfo = parseJwt(credentialResponse.credential)
                    let user = {
                        googleUserInfo: googleUserInfo,
                        googleCredentialResponse: credentialResponse,
                        jwt: credentialResponse.credential
                    }
                    setUserLocalStorage(user)
                    setUserDBLocalStorage(user.jwt)
                    //navigate("/")
                }}
                onError={() => {
                    console.log('Login Failed');
                    setUserLocalStorage(null)
                }}

            />
        </LayoutLoggedOut>
    );
}

export default LoginGooglePage;