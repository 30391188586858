// Layout.js
import React, {useState} from 'react';
import { Navigate, Link } from 'react-router-dom';
import './LayoutMenu.css';

/*
    esempio 1 preso da qui: https://dev.to/codeply/bootstrap-5-sidebar-examples-38pb
    https://www.codeply.com/p/WGCqYEiPBg
*/
const LayoutMenu = ({children}) => {
    // utente di Google Workspace
    let user = JSON.parse(localStorage.getItem('user'));
    console.log("User (LayoutMenu): ", user)

    let usrDB = JSON.parse(localStorage.getItem('userDB'));
    console.log("UserDB (LayoutMenu): ", usrDB)

    function contieneRuoloUtente(role) {
        return usrDB.Roles.some(item => item.Role === role);
    }

    return (
        <>
            {(user && usrDB) ? (
                <div className="container-fluid">
                    <div className="row flex-nowrap">
                        <div className="col-auto px-0 bg-1908 col-menu-1908">
                            <div
                                className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                                <a href="/"
                                   className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                                    {/*<span className="fs-5 d-none d-sm-inline">Menu</span>*/}
                                    <img id="logo-menu" src="logo1908.svg" alt="Noberasco 1908"/>
                                </a>

                                <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                                    id="menu">
                                    <li className="nav-item">
                                        <Link className="nav-link px-0 align-middle" to='/'>
                                            <i className="fs-4 bi-house"></i> <span
                                            className="ms-1 d-none d-sm-inline">Home</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="nav-link px-0 align-middle" to='/bolle-viaggianti'>
                                            <i className="fs-4 bi-table"></i> <span
                                            className="ms-1 d-none d-sm-inline">Bolle</span>
                                        </Link>
                                    </li>

                                    {/*TODO fare una gestione intelligente*/}
                                    {contieneRuoloUtente("ROLE_ADMIN") ? (
                                        <li>
                                            <Link className="nav-link px-0 align-middle" to='/avvisi'>
                                                <i className="fs-4 bi-virus2"></i> <span
                                                className="ms-1 d-none d-sm-inline">Avvisi</span>
                                            </Link>
                                            <Link className="nav-link px-0 align-middle" to='/amministrazione'>
                                                <i className="fs-4 bi-subscript"></i> <span
                                                className="ms-1 d-none d-sm-inline">Amm.</span>
                                            </Link>
                                        </li>
                                    ): <></> }
                                </ul>


                                <>
                                    <hr></hr>
                                    <div className="dropdown pb-4">
                                        <a href="#"
                                           className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                                           id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img
                                                src="https://dybgkj0pqqfv5.cloudfront.net/Risorse/NOBERA/Images/noberasco1908/logoNoberasco.svg"
                                                alt="hugenerd" width="30" height="30"
                                                className="rounded-circle"></img>
                                            <span className="d-none d-sm-inline mx-1">{user.googleUserInfo.given_name}</span>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow"
                                            aria-labelledby="dropdownUser1">
                                            {/*<li><a className="dropdown-item" href="#">New project...</a></li>
                                            <li><a className="dropdown-item" href="#">Settings</a></li>
                                            <li><a className="dropdown-item" href="#">Profile</a></li>*/}
                                            <span className="p-2">{user.googleUserInfo.email}</span>
                                            <li>
                                                <hr className="dropdown-divider"></hr>
                                            </li>
                                            <li><a className="dropdown-item" href="/logout">Logout</a></li>
                                        </ul>
                                    </div>
                                </>

                            </div>
                        </div>
                        <div className="col py-3">
                            {children}
                        </div>
                    </div>
                </div>
            ) : (
                <Navigate to="/login" />
            )}
        </>
    );
};

export default LayoutMenu;