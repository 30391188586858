import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import BollaPage from './BollaPage';
import BolleViaggiantiPage from './BolleViaggiantiPage';
import reportWebVitals from './reportWebVitals';
import LoginGooglePage from "./LoginGooglePage";
import Home from "./Home";
import AvvisiPage from "./pages/avvisi/AvvisiPage";

import {
    createBrowserRouter, Navigate,
    RouterProvider,
} from "react-router-dom";
import AmnPage from "./pages/amn/AmnPage";



const Logout = () => {
    localStorage.removeItem('user')
    localStorage.removeItem('userDB')
    console.log('LOGOUT')
    return (
        <>
            return <Navigate to="/login" replace />;
        </>
    );
};

//https://reactrouter.com/en/main/start/tutorial
const router = createBrowserRouter([
    {
        path: "/login",
        element: <LoginGooglePage />,
    },
    {
        path: "/login-google",
        element: <LoginGooglePage />,
    },
    {
        path: "/logout",
        element: <Logout />,
    },
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/bolla",
        element: <BollaPage />,
    },
    {
        path: "/bolle-viaggianti",
        element: <BolleViaggiantiPage />,
    },
    {
        path: "/avvisi",
        element: <AvvisiPage />,
    },
    {
        path: "/amministrazione",
        element: <AmnPage />,
    },
]);

//Rendering doppio: https://stackoverflow.com/questions/48846289/why-is-my-react-component-is-rendering-twice
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <App>
            {/*<React.StrictMode>*/}
            <GoogleOAuthProvider clientId="1082727366583-jbsl8ac2kpgn48fa0vedm6dq6nvtnnhn.apps.googleusercontent.com">
                <RouterProvider router={router} />
            </GoogleOAuthProvider>
            {/*</React.StrictMode>*/}
        </App>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//https://accounts.google.com/gsi/select?client_id=546006051305-vd3i12o0q7rmu8l3lmve9usc73iibgo6.apps.googleusercontent.com&ux_mode=popup&ui_mode=card&as=azrjFYh8yEOIs7e4Kwh%2BLQ&channel_id=589413c4cb2923409cc32d609100023d40f4da755b63f492a42f49847bbc3eed&origin=http%3A%2F%2Flocalhost%3A3000