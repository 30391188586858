// Converte le date as YYYYMMDD in DD/MM/YYYY
export function convertiDataYYYYMMDD(dataInt) {
    if(typeof dataInt == 'undefined' || dataInt < 10000000){
        return ''
    }

    const dataString = dataInt.toString();

    const anno = dataString.slice(0, 4);
    const mese = dataString.slice(4, 6);
    const giorno = dataString.slice(6, 8);

    const data = new Date(`${anno}-${mese}-${giorno}`);

    const giornoFormattato = String(data.getDate()).padStart(2, '0');
    const meseFormattato = String(data.getMonth() + 1).padStart(2, '0'); // Mese è basato su zero
    const annoFormattato = data.getFullYear();

    return `${giornoFormattato}/${meseFormattato}/${annoFormattato}`;
}



// Funzione per formattare la data da YYYY-MM-DD a YYYYMMDD
export function convertiDataToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}${month}${day}`;
}

// Funzione per formattare la data da YYYYMMDD a YYYY-MM-DD
export function convertiDataFromYYYYMMDD(dateString) {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}-${month}-${day}`;
}