import './LayoutLoggedOut.css';

/*
    esempio 1 preso da qui: https://dev.to/codeply/bootstrap-5-sidebar-examples-38pb
    https://www.codeply.com/p/WGCqYEiPBg
*/
const LayoutLoggedOut = ({children}) => {
    return (
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <div className="col-auto px-0 bg-1908 col-menu-1908">
                    <div
                        className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                        <a href="/"
                           className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                            {/*<span className="fs-5 d-none d-sm-inline">Menu</span>*/}
                            <img id="logo-menu" src="logo1908.svg" alt="Noberasco 1908"/>
                        </a>
                    </div>
                </div>
                <div className="col py-3">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default LayoutLoggedOut;