import '../../App.css';
import LayoutMenu from "../../LayoutMenu";
import {useNavigate} from "react-router-dom";
import React, {useState, useEffect, useMemo} from 'react';

function AvvisiPage() {
    let [artMancantiBolle, setArtMancantiBolle] = useState([]);
    let navigate = useNavigate();

    // https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication#answer-43133108

    function reloadState() {
        let userLogged = JSON.parse(localStorage.getItem('user'))

        fetch(process.env.REACT_APP_API_URL + "/bolle-codici-mancanti",
            {
                headers: {
                    'Authorization': 'Bearer ' + userLogged.jwt,
                }
            })
            //.then((res) => res.json())
            .then((res) => {
                if (!res.ok) {
                    // Se il codice di stato non è OK (200), solleva un errore
                    throw new Error(`Errore API: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                if (data == null) {
                    setArtMancantiBolle([]);
                } else {
                    setArtMancantiBolle(data);
                }
            })
            .catch((err) => {
                console.log(err.message);
                if (err.message.includes("40")) {
                    alert("Errore di autenticazione, ritenta il login ed in caso di problemi contatta ict@noberasco.it")
                    navigate('/logout')
                } else if (err.message.includes("50")) {
                    alert("Errore del server. Contatta ICT")
                    //navigate('/home')
                }
            });
    }

    useEffect(() => {
        console.log(`/messaggi`);
        reloadState()
    }, []);

    return (
        <LayoutMenu>
            {/*https://stackoverflow.com/questions/52039083/handle-back-button-with-react-router#answer-72334436*/}
            <div className="breadcrumb-1"><a href="#" onClick={() => navigate(-1)}><i
                className="fs-4 bi-arrow-left"></i> INDIETRO</a></div>
            <div className="col col-sm-12">
                {/* {artMancantiBolle.length === 0 && (
                    <div>Nessuna riga da mostrare.</div>
                )}*/}


                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Articoli in transito non presenti in Passepartout</h5>
                        {artMancantiBolle.length === 0 ? (
                            <div>Nessun articolo da mostrare.</div>
                        ) : (
                            <table className="table table-sm w-auto">
                                <thead>
                                <tr>
                                    <th>Cod.</th>
                                    <th>Art.</th>
                                </tr>
                                </thead>
                                <tbody>
                                {artMancantiBolle.map((d, v) => {
                                    return (
                                        <tr key={d.codice.trim() + v}>
                                            <td>{d.codice}</td>
                                            <td>{d.descrizione}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>

            </div>
        </LayoutMenu>
    );
}

export default AvvisiPage;